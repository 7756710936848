<template>
  <!-- Error page-->
  <div class="misc-wrapper error-404">
    <b-link
      class="brand-logo d-flex align-items-center"
      :to="{path:'/'}"
    >
      <b-img
        fluid
        alt="img"
        :class="appName ? 'logo-img' : 'logo-img-full'"
        :src="logo"
      />
      <h6 class="mb-0 app-name">
        {{ appName }}
      </h6>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <!-- image -->
      <b-img
        fluid
        :src="imgUrl"
        class="img"
        alt="img"
      />
      <div class="w-100 text-center mt-4">
        <h2 class="mb-1 title">
          Page Not found
        </h2>
        <p class="mb-2 des">
          Oops!  The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  mixins: [envMixin],
  data() {
    return {
      downImg: require('@/assets/images/pages/error-404.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-404.png')
        return this.downImg
      }
      return this.downImg
    },

    appName() {
      return process.env.VUE_APP_LOGO_NAME
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .logo-img{
    max-width: 50px;
  }

  .logo-img-full {
    width: 135px;
  }

  .app-name{
    margin-left: .5rem;
    max-width: 100px;
  }

  .img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .title {
    font-size: 34px;
  }

  .des {
    font-size: 20px;
    line-height: 30px;
  }
</style>
